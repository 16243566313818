import axios from "axios"

export default class User {
  constructor(data) {
    Object.assign(this, data)
  }

  isLoggedIn() {
    return this.token !== null
  }

  canAccessPage(isPrivate) {
    if (!isPrivate) {
      return true
    }

    return this.isLoggedIn()
  }

  hasOrderedAGift() {
    return this.has_ordered_a_gift
  }

  canOrderGift() {
    return this.isLoggedIn && !this.hasOrderedAGift()
  }

  logout(dispatch) {
    axios
      .delete("/api/auth/logout")
      .catch(() => {
        console.log("User has already been logged out.")
      })
      .finally(() => {
        dispatch({
          type: "LOGOUT",
        })
      })
  }
}
