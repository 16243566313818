exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-forgot-password-jsx": () => import("./../../../src/pages/account/forgot-password.jsx" /* webpackChunkName: "component---src-pages-account-forgot-password-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../../../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-cadeaux-jsx": () => import("./../../../src/pages/cadeaux.jsx" /* webpackChunkName: "component---src-pages-cadeaux-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-merci-jsx": () => import("./../../../src/pages/merci.jsx" /* webpackChunkName: "component---src-pages-merci-jsx" */),
  "component---src-pages-preview-[type]-[id]-jsx": () => import("./../../../src/pages/preview/[type]/[id].jsx" /* webpackChunkName: "component---src-pages-preview-[type]-[id]-jsx" */),
  "component---src-pages-reset-email-jsx": () => import("./../../../src/pages/reset-email.jsx" /* webpackChunkName: "component---src-pages-reset-email-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-poi-template-jsx": () => import("./../../../src/templates/poiTemplate.jsx" /* webpackChunkName: "component---src-templates-poi-template-jsx" */)
}

