import React from "react"
import axios from "axios"
import User from "models/user"

const initialState = {
  token: null,
  first_name: null,
  group: null,
  company: null,
  has_ordered_a_gift: false,
  ordered_gift: false,
}

/**
 * List of allowed actions to be applied to the user object
 */
const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    /**
     * LOAD registers the token
     */
    case "LOAD":
      let storedUser = localStorage.getItem("user_context")

      if (!storedUser) {
        return new User(initialState)
      }

      storedUser = JSON.parse(storedUser)

      if (storedUser.token) {
        axios.defaults.headers["Authorization"] = "Bearer " + storedUser.token
      }

      newState = {
        ...initialState,
        token: storedUser.token,
      }
      break
    /**
     * LOGIN registers the token
     */
    case "LOGIN":
      newState = {
        ...state,
        first_name: action.profile.first_name,
        group: action.profile.groups ? action.profile.groups[0] : null,
        company: action.profile.company ? action.profile.company : null,
        has_ordered_a_gift: action.profile.has_ordered_a_gift,
        ordered_gift: action.profile.ordered_gift,
        token: action.token,
      }
      axios.defaults.headers["Authorization"] = "Bearer " + action.token
      break

    /**
     * LOGOUT unregisters the token
     */
    case "LOGOUT":
      localStorage.removeItem("user_context")
      axios.defaults.headers["Authorization"] = null
      newState = initialState
      break

    /**
     * UPDATE_PROFILE add profile informations
     */
    case "UPDATE_PROFILE":
      newState = {
        ...state,
        first_name: action.profile.first_name,
        group: action.profile.groups ? action.profile.groups[0] : null,
        company: action.profile.company ? action.profile.company : null,
        ordered_gift: action.profile.ordered_gift,
        has_ordered_a_gift: action.profile.has_ordered_a_gift,
      }
      break

    case "HAS_ORDERED":
      newState = {
        ...state,
        has_ordered_a_gift: true,
      }
      break

    default:
      throw new Error()
  }

  const user = new User(newState)

  if (typeof window !== "undefined") {
    localStorage.setItem("user_context", JSON.stringify(newState))
  }

  return user
}

const UserContext = React.createContext()

const UserContextProvider = ({ children }) => {
  const userReducer = React.useReducer(reducer, initialState, () => {
    return new User(initialState)
  })
  return (
    <UserContext.Provider value={userReducer}>{children}</UserContext.Provider>
  )
}

const useUser = () => React.useContext(UserContext)

export { useUser, UserContext, UserContextProvider }
